import React from 'react'

const House = () => {
  return (
    <div className="House" style={{height: '200px', paddingTop: '5%'}}>
      <svg
        viewBox="0 0 80 80"
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
      >
        <g>
          <path
            strokeMiterlimit="10"
            strokeLinejoin="round"
            strokeWidth="3.2"
            stroke="#333"
            fill="white"
            d="M72 43.612l-11.15-8.865V20.834a1.82 1.82 0 00-1.82-1.82h-5.588a1.82 1.82 0 00-1.82 1.82v6.576L40 18.171 8 43.612l4.086 5.14 4.662-3.707v13.708a3.077 3.077 0 003.077 3.077h40.351a3.077 3.077 0 003.077-3.077V45.045l4.661 3.706L72 43.612z"
          />
          <path
            className="House-gear"
            fill="#77a4bd"
            d="M51.24 45.744c.053-.442.087-.888.087-1.344s-.034-.902-.086-1.344l-3.066-.434a8.322 8.322 0 00-1.14-2.744l1.862-2.475a11.364 11.364 0 00-1.901-1.9l-2.475 1.861a8.314 8.314 0 00-2.744-1.14l-.434-3.066c-.441-.052-.888-.086-1.344-.086s-.902.034-1.344.086l-.433 3.066a8.321 8.321 0 00-2.744 1.14l-2.476-1.862a11.364 11.364 0 00-1.9 1.901l1.861 2.475a8.314 8.314 0 00-1.14 2.744l-3.065.434c-.052.442-.087.888-.087 1.344s.035.902.087 1.344l3.065.434a8.321 8.321 0 001.14 2.744l-1.861 2.475a11.394 11.394 0 001.9 1.9l2.476-1.861a8.298 8.298 0 002.744 1.14l.433 3.066c.442.052.888.086 1.344.086s.903-.034 1.344-.086l.434-3.066a8.314 8.314 0 002.744-1.14l2.475 1.862a11.364 11.364 0 001.9-1.901l-1.86-2.475a8.314 8.314 0 001.14-2.744l3.065-.434zM40 49.73a5.33 5.33 0 110-10.66 5.33 5.33 0 010 10.66z"
          />
        </g>
      </svg>
    </div>
  )
}

export default House